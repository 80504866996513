import React from "react";

const ThreeRectangles = () => {
  return (
    <div id="threeRectangles">
      <div className="container" style={{ marginTop: "-120px" }}>
        <div className="row justify-content-around">
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "120px",
                  marginTop: "-10px",
                  marginBottom: "-20px",
                }}
                src={"img/statistics/4.png"}
                alt="Icon 2"
              />
              <div
                style={{ fontWeight: "bold", fontSize: "24px" }}
                className="text"
              >
                Kişisel ve Toplumsal Gelişim
              </div>
              <div className="text">
                Üyelerimizin kişisel ve toplumsal gelişimine odaklanarak,
                liderlik becerilerini güçlendirme, farklı kültürleri bir araya
                getirme ve sosyal etkileşimi artırma amacıyla çeşitli projeler
                tasarlıyoruz. Bu sayede üyelerimiz ve toplumumuzda pozitif
                değişimler yaratmayı hedefliyoruz.
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "120px",
                  marginTop: "-10px",
                  marginBottom: "-20px",
                }}
                src={"img/statistics/3.png"}
                alt="Icon 1"
              />
              <div
                style={{ fontWeight: "bold", fontSize: "24px" }}
                className="text"
              >
                Toplum Sorunlarına Çözüm Buluyoruz
              </div>
              <div className="text">
                Rotaract kulüpleri olarak, yoksulluk, temiz su ve hijyen
                ihtiyacı, hastalıklar ve eğitim gibi çeşitli toplumsal sorunlara
                odaklanıyor ve bu sorunlara kalıcı çözümler üretmek için
                projeler geliştiriyoruz.
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="rectangle text-center p-4">
              <img
                style={{
                  width: "120px",
                  marginTop: "-10px",
                  marginBottom: "-20px",
                }}
                src={"img/statistics/5.png"}
                alt="Icon 3"
              />
              <div
                style={{ fontWeight: "bold", fontSize: "24px" }}
                className="text"
              >
                Umut Kaynağı Oluyoruz
              </div>
              <div className="text">
                Rotaract, geniş kulüp ağı ve üye potansiyeli ile toplumun her
                kesimine ulaşıp destek sağlıyor. Sorunların çözümü için proaktif
                bir yaklaşımla hareket ederek, toplumlarda olumlu bir etki
                yaratmayı ve gelecek nesillere daha iyi bir dünya bırakmayı
                amaçlıyoruz.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeRectangles;
