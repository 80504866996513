import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Rotaract } from "./components/rotaract";
import { Contact } from "./components/contact";
import { About } from "./components/about";
import { FocusPoints } from "./components/focusPoints";
import { Gallery } from "./components/gallery";
import { Team } from "./components/Team";
import JsonData from "./data/data.json";
import "./App.css";
import { Rotary } from "./components/rotary";
import Social from "./components/social";
import ThreeRectangles from "./components/threeRectangles";
import Statistics from "./components/statistics";
import { Projects } from "./components/projects";
import { Fourway } from "./components/fourway";
import { UsOnMedia } from "./components/usonmedia";
import { ContactPath } from "./components/contactPath";
import { Tando } from "./components/tando";
import { FocusPointsPath } from "./components/focusPointsPath";

const App = () => {
  const landingPageData = JsonData;
  const socialData = {
    youtube: "https://www.youtube.com/@ankaratandoganrotaractkulu5853",
    instagram: "https://www.instagram.com/tandoganrac/",
  };
  return (
    <Router>
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home data={landingPageData} />} />
          <Route
            path="/rotaract"
            element={<Rotaract data={JsonData.Rotaract} />}
          />
          <Route path="/rotary" element={<Rotary data={JsonData.Rotary} />} />
          <Route path="/team" element={<Team data={JsonData.Team} />} />
          <Route path="/tando" element={<Tando data={JsonData.Tando} />} />
          <Route
            path="/focus"
            element={<FocusPointsPath data={JsonData.Services} />}
          />
          <Route
            path="/quaternary"
            element={<Fourway data={JsonData.Fourway} />}
          />
          <Route path="/projects" element={<Projects data={JsonData} />} />
          <Route
            path="/gallery"
            element={<Gallery data={JsonData.Gallery} />}
          />
          <Route path="/press" element={<UsOnMedia data={JsonData} />} />
          <Route path="/contact" element={<ContactPath data={JsonData} />} />
        </Routes>
        <Social data={socialData} />
      </div>
    </Router>
  );
};

const Home = ({ data }) => {
  return (
    <>
      <Header data={data.Header} />
      <ThreeRectangles />
      <Statistics />
      <About data={data.Leader} />
      <FocusPoints data={data.Services} />
      <Gallery data={data.Gallery} />
      <Contact data={data.Contact} />
    </>
  );
};

export default App;
