import React from "react";
import { About } from "./about";

export const Projects = (props) => {
  return (
    <>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
              PROJELERİMİZ
            </h2>
          </div>
        </div>
      </div>
      <About data={props.data.Tandomama} />
      <About data={props.data.Rofife} />
      <About data={props.data.Th1k} />
      <About data={props.data.RotaWeek} />
    </>
  );
};
