import React from "react";

export const About = (props) => {
  let rightImage = "";
  console.log(props.data.imgSource);
  if (props.data.imgSource === "img/press/hatay1.jpeg") {
    rightImage = (
      <img
        src={props.data.imgSource}
        className="img-responsive"
        alt=""
        style={{ width: "500px" }}
      />
    );
  } else if (props.data.imgSource === "img/president/image.png") {
    rightImage = (
      <img
        src={props.data.imgSource}
        className="img-responsive"
        alt=""
        style={{ width: "900px" }}
      />
    );
  } else {
    rightImage = (
      <img src={props.data.imgSource} className="img-responsive" alt="" />
    );
  }

  return (
    <div id="about" style={{ background: props.data.background }}>
      <div className="container">
        <div className="row">
          {props.data.reverse ? (
            <>
              <div className="col-xs-12 col-md-6">
                {" "}
                <img
                  src={props.data.imgSource}
                  className="img-responsive"
                  alt=""
                />{" "}
              </div>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>{props.data.title}</h2>
                  <p>{props.data ? props.data.paragraph : "loading..."}</p>
                  <p>{props.data.paragraph2 && props.data.paragraph2} </p>
                  <p>{props.data.paragraph3 && props.data.paragraph3} </p>
                  <p>{props.data.paragraph4 && props.data.paragraph4} </p>
                  <p>{props.data.paragraph5 && props.data.paragraph5} </p>
                  <p>{props.data.paragraph6 && props.data.paragraph6} </p>
                  {/* <h3>Why Choose Us?</h3> */}
                  <div className="list-style">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {props.data
                          ? props.data.Why.map((d, i) => (
                              <li key={`${d}-${i}`}>{d}</li>
                            ))
                          : "loading"}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {props.data
                          ? props.data.Why2.map((d, i) => (
                              <li key={`${d}-${i}`}> {d}</li>
                            ))
                          : "loading"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-xs-12 col-md-6">
                <div className="about-text">
                  <h2>{props.data.title}</h2>
                  <p>{props.data ? props.data.paragraph : "loading..."}</p>
                  <p>{props.data.paragraph2 && props.data.paragraph2} </p>
                  <p>{props.data.paragraph3 && props.data.paragraph3} </p>
                  <p>{props.data.paragraph4 && props.data.paragraph4} </p>
                  <p>{props.data.paragraph5 && props.data.paragraph5} </p>
                  <p>{props.data.paragraph6 && props.data.paragraph6} </p>
                  <p>{props.data.paragraph7 && props.data.paragraph7} </p>
                  <p>{props.data.paragraph8 && props.data.paragraph8} </p>
                  <p>{props.data.paragraph9 && props.data.paragraph9} </p>
                  <p>{props.data.paragraph10 && props.data.paragraph10} </p>
                  {/* <h3>Why Choose Us?</h3> */}
                  <div className="list-style">
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {props.data
                          ? props.data.Why.map((d, i) => (
                              <li key={`${d}-${i}`}>{d}</li>
                            ))
                          : "loading"}
                      </ul>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                      <ul>
                        {props.data
                          ? props.data.Why2.map((d, i) => (
                              <li key={`${d}-${i}`}> {d}</li>
                            ))
                          : "loading"}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">{rightImage}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
