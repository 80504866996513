import React from "react";
import { Contact } from "./contact";

export const ContactPath = (props) => {
  return (
    <>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
              İLETİŞİM
            </h2>
          </div>
        </div>
      </div>

      <Contact data={props.data.Contact} />
    </>
  );
};
