import React from "react";

const Social = (props) => {
  console.log(props.data);
  return (
    <div id="social">
      <div className="container"></div>
      <div className="col-md-12">
        <div className="row">
          <div className="social">
            <ul>
              <li>
                <a href={props.data ? props.data.youtube : "/"}>
                  <i className="fa fa-youtube"></i>
                </a>
              </li>
              <li>
                <a href={props.data ? props.data.instagram : "/"}>
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Social;
