import React from "react";
import { About } from "./about";

export const UsOnMedia = (props) => {
  return (
    <>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
              BASINDA BİZ
            </h2>
          </div>
        </div>
      </div>
      <About data={props.data.Press} />
      <div style={{ textAlign: "center", marginTop: "-20px" }}>
        <p style={{ marginBottom: "50px", marginTop: "-100px" }}>
          Kaynak:{" "}
          <a href="https://www.hataymahallihaber.com/haber/hatay-dan-ataya-sevgi-yolculugu/">
            Hatay Mahalli Haber
          </a>
        </p>
      </div>
    </>
  );
};
