import React from "react";

const Statistics = (props) => {
  return (
    <div id="statistics">
      <div className="container">
        <div className="left-column">
          <h2 style={{ fontStyle: "italic" }}>
            {props.title}"PEOPLE OF ACTION"
          </h2>
          <p style={{ marginTop: "40px" }}>
            {props.paragraph}Dünyanın her köşesinden Rotaract kulüpleri,
            yaşadıkları topluma çeşitli projelerle fayda sağlamak için her gün
            yeni bir adım atıyor; bu amaçla çıkılan yolda aynı zamanda dostluk,
            dayanışma ve topluma faydalı bir birey olmanın önemini de hep
            birlikte pekiştiriyoruz.
          </p>
        </div>
        <div className="right-column">
          <ul>
            <li>
              <img
                style={{ width: "150px" }}
                src={"img/statistics/7.png"}
                alt="Icon 1"
              />
              <hr />
              <span>{props.regionCount}180+</span>
              <p>Ülkede</p>
            </li>
            <li>
              <img
                style={{ width: "150px" }}
                src={"img/statistics/6.png"}
                alt="Icon 2"
              />
              <hr />
              <span>{props.cityCount}10.000+</span>
              <p>Rotaract Kulübü</p>
            </li>
            <li>
              <img
                style={{ width: "150px" }}
                src={"img/statistics/8.png"}
                alt="Icon 3"
              />
              <hr />
              <span>{props.countryCount}203.000+</span>
              <p>Rotaractör</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
