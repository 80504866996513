import React, { useState } from "react";

export const Tando = (props) => {
  const color = "linear-gradient(to right, #c13563 40%, #18398b 100%)";
  const customTitleStyle = {
    background: color,
    // Diğer stilleri buraya ekleyebilirsiniz
  };

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
            HAKKIMIZDA
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div>
              <div>
                <div
                  className="section-title h3 custom-title"
                  style={customTitleStyle}
                >
                  {props.data.title}
                </div>{" "}
                <div className="accordion-content">
                  <p>{props.data.content1}</p>
                  <p>{props.data.content2}</p>
                  <p>{props.data.content3}</p>
                  <p>{props.data.content4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
