import React from "react";

export const Team = (props) => {
  return (
    <>
      <div id="features" className="text-center">
        <div className="container">
          <div className="col-md-10 col-md-offset-1 section-title">
            <h2 style={{ marginTop: "130px", marginBottom: "-40px" }}>
              HAKKIMIZDA
            </h2>
          </div>
        </div>
      </div>
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-12 col-md-offset-0 section-title">
            <h2>YÖNETİM KURULU ÜYELERİ VE KOMİTE BAŞKANLARI</h2>
            <p>2024 - 2025 Dönemi Yönetim Kurulumuz ve Komite Başkanlarımız</p>
          </div>
          <div
            id="row"
            className="row"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-2 col-sm-2 team"
                    style={{
                      width: "20%",
                      maxWidth: "240px",
                      margin: "10px 0",
                    }}
                  >
                    <div className="thumbnail">
                      {" "}
                      {d.img ? (
                        <img src={d.img} alt="..." className="team-img" />
                      ) : (
                        ""
                      )}
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p>{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    </>
  );
};
